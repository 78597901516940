// https://github.com/nuxt/framework/issues/6711

export enum SectionComponents {
  SectionAccordionRecord = 'SectionAccordion',
  SectionArticleListRecord = 'SectionArticles',
  SectionBodyRecord = 'SectionBody',
  SectionContactFormRecord = 'SectionContactForm',
  SectionCtaRecord = 'SectionCta',
  SectionCtaNarrowRecord = 'SectionCtaNarrow',
  SectionFaqRecord = 'SectionFaq',
  SectionFeaturesListRecord = 'SectionFeatures',
  SectionFeaturesMediaBlockRecord = 'SectionFeaturesMedia',
  SectionTitleRecord = 'SectionTitle',
  SectionHeroARecord = 'SectionHeroA',
  SectionHeroBRecord = 'SectionHeroB',
  SectionHeroCRecord = 'SectionHeroC',
  SectionHeroHtmlRecord = 'SectionHeroHTML',
  SectionImageGalleryRecord = 'SectionImageGallery',
  SectionMediaBlockRecord = 'SectionMedia',
  SectionMediaWideRecord = 'SectionMediaWide',
  SectionNewsletterRecord = 'SectionNewsletter',
  SectionPartnersListRecord = 'SectionPartners',
  SectionPlanComparisonRecord = 'SectionPlanComparison',
  SectionRelatedArticleRecord = 'SectionRelatedArticles',
  SectionTabsListRecord = 'SectionTabs',
  SectionTextBlockRecord = 'SectionTextBlocks',
  SectionTestimonialsListRecord = 'SectionTestimonials',
  SectionVideoRecord = 'SectionVideo',
  SectionThankYouRecord = 'SectionThankYou'
}

export enum MenuBlocks {
  MenuCtaRecord = 'MenuCallToAction',
  MenuGroupRecord = 'MenuGroup'
}

export enum Blocks {
  HighlightRecord = 'Highlight',
  HighlightListRecord = 'HighlightList',
  CtaRecord = 'CTA',
  MediaTipRecord = 'MediaTip'
}
